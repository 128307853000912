/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
@font-face {
  font-family: 'Documenta Regular';
  src: url("../../fonts/Documenta/D019W13T.eot");
  src: url("../../fonts/Documenta/D019W13T.eot?#iefix") format("embedded-opentype"), url("../../fonts/Documenta/D019W13T.woff") format("woff"), url("../../fonts/Documenta/D019W13T.ttf") format("truetype"), url("../../fonts/Documenta/D019W13T.svg#dtl_documenta_wtregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Documenta Italic';
  src: url("../../fonts/DocumentaItalic/DTL Documenta ST Italic.otf");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Suisse Light';
  src: url("../../fonts/Suisse/SuisseIntl-Light-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Book';
  src: url("../../fonts/Suisse/SuisseIntl-Book-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Semi Bold';
  src: url("../../fonts/Suisse/SuisseIntl-SemiBold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Bold';
  src: url("../../fonts/Suisse/SuisseIntl-Bold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

.clamp {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical; }
  .clamp-1 {
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-2 {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-3 {
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-5 {
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-9 {
    -webkit-line-clamp: 9;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }

.column-count--2 {
  -moz-column-count: 2;
       column-count: 2;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--3 {
  -moz-column-count: 3;
       column-count: 3;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--4 {
  -moz-column-count: 4;
       column-count: 4;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--5 {
  -moz-column-count: 5;
       column-count: 5;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

/**************/
/*** COLORS ***/
/**************/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.photo-tile-container .hasVideo {
  position: relative;
  overflow: hidden; }
  .photo-tile-container .hasVideo video {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0;
    -o-object-fit: contain;
       object-fit: contain; }
